import { lazy } from "react";


// use lazy for better code splitting, a.k.a. load faster
const HomePage = lazy(() => import("../pages/home/Home"));
const ExplorePage = lazy(() => import("../pages/explore/Explore"));
const PrivacyPolicyPage = lazy(() => import("../pages/privacyPolicy/PrivacyPolicy"));
const TermsAndConditionPage = lazy(() => import("../pages/termsAndCondition/TermsAndCondition"));

const routes = [
  {
    path: "/",
    component: HomePage,
  },
  {
    path: "/explore-market",
    component: ExplorePage,
  },
  {
    path: "/privacy-policy",
    component: PrivacyPolicyPage,
  },
  {
    path: "/terms-and-condition",
    component: TermsAndConditionPage,
  },
 
];


export default routes;
